import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';

import { environment } from '@env/environment';
import { RouteReusableStrategy, ErrorHandlerInterceptor, SharedModule } from '@shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProjectFilesModule } from './project-files/project-files.module';
import { DataTablesModule } from 'angular-datatables';
import { AnalyticsModule } from './analytics/analytics.module';
import { AlleleUsageModule } from './allele-usage/allele-usage.module';
import { GeneAnalyticsModule } from './gene-analytics/gene-analytics.module';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './@shared/http/auth.interceptor';
import { importProvidersFrom } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FlairrStatsModule } from './flairr-stats/flairr-stats.module';
import { FlairrSummaryModule } from './flairr-summary/flairr-summary.module';
import { provideRouter } from '@angular/router';

import { environment as env } from '../environments/environment';
import awsconfig from '../aws-exports';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';

Amplify.configure(awsconfig);
PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    ShellModule,
    HomeModule,
    ProjectFilesModule,
    DataTablesModule,
    AmplifyAuthenticatorModule,
    AnalyticsModule,
    AlleleUsageModule,
    GeneAnalyticsModule,
    FlairrStatsModule,
    FlairrSummaryModule,
    BsDropdownModule,
    PlotlyModule,
    AuthModule, // let these two come last !!
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    // https://stackoverflow.com/questions/78182287/how-can-i-install-ngx-bootstrap-in-project-angular-17/78393183#78393183
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(ModalModule.forRoot()),
  ],
})
export class AppModule {}
