import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectService } from './header/header.project.service';
import { SharedModule } from '@shared';

@NgModule({
  imports: [CommonModule, RouterModule, FontAwesomeModule, SharedModule],
  declarations: [HeaderComponent, ShellComponent],
})
export class ShellModule {}
