import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FlairrStatsComponent } from './flairr-stats.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  Shell.publicRoutes([{ path: 'flairr-stats', component: FlairrStatsComponent, data: { title: 'Flairr Stats' } }]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FlairrStatsRoutingModule {}
