<nav class="navbar navbar-expand-lg navbar-light" style="background: rgb(75, 152, 228)">
  <div class="container-fluid">
    <a class="navbar-brand text-white" href="https://www.clareobio.com">clareo</a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link text-white" [routerLinkActive]="['active']" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" [routerLinkActive]="['active']" routerLink="/about">About</a>
        </li>
        <li class="nav-item" *ngIf="credentialsService.credentials()">
          <a class="nav-link text-white" [routerLinkActive]="['active']" routerLink="/download">Samples</a>
        </li>
        <li class="nav-item dropdown" *ngIf="credentialsService.credentials()">
          <a
            class="nav-link dropdown-toggle text-white"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Capture Analysis
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" [routerLinkActive]="['active']" [routerLink]="['/analytics', 'Capture probe']">
                Allele Discovery
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                [routerLinkActive]="['active']"
                [routerLink]="['/gene-analytics', 'Capture probe']"
              >
                Single Gene
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="credentialsService.credentials()">
          <a
            class="nav-link dropdown-toggle text-white"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            FLAIRR Analysis
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/flairr-summary">
                Project Summary
              </a>
            </li>
            <li>
              <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/allele-usage"> Allele Usage </a>
            </li>
            <li>
              <a class="dropdown-item" [routerLinkActive]="['active']" [routerLink]="['/gene-analytics', 'FLAIRR-Seq']">
                Single Gene Usage
              </a>
            </li>
            <li>
              <a class="dropdown-item" [routerLinkActive]="['active']" [routerLink]="['/analytics', 'FLAIRR-Seq']">
                Allele Discovery
              </a>
            </li>
            <li>
              <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/flairr-stats">
                Sample reads and QC
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav">
        <div *ngIf="projectService.projectDetails().length > 0">
          <form class="d-flex" role="search">
            <select class="form-select" (change)="projectService.setCurrentProjectFromEvent($event)">
              <option *ngFor="let project of projectService.projectDetails()" [value]="project.project_id">
                {{ project.project_data.project_name }}
              </option>
            </select>
          </form>
        </div>
        <ngbd-theme-picker></ngbd-theme-picker>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle text-white"
            href="#"
            id="userDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <fa-icon [icon]="faUser"></fa-icon>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
            <li *ngIf="credentialsService.credentials()">
              <span class="dropdown-item-text">Logged in as {{ credentialsService.email() }}</span>
            </li>
            <li>
              <a class="dropdown-item" *ngIf="!credentialsService.credentials()" routerLink="/login">Login</a>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a class="dropdown-item" *ngIf="credentialsService.credentials()" (click)="logout()">Logout</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
