import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from '@shared';
import { AlleleUsageRoutingModule } from './allele-usage-routing.module';
import { AlleleUsageComponent } from './allele-usage.component';

@NgModule({
  imports: [CommonModule, SharedModule, NgChartsModule, AlleleUsageRoutingModule, BrowserModule],
  declarations: [AlleleUsageComponent],
})
export class AlleleUsageModule {}
