import { AfterViewInit, Component, effect, inject, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-buttons-dt';
import { BackendService } from '@app/@shared/backend.service';
import { ProjectService } from '@app/shell/header/header.project.service';
import { Chart, ChartConfiguration, LinearScale, CategoryScale } from 'chart.js';
import { BoxPlotController, BoxAndWiskers, ViolinController, Violin } from '@sgratzl/chartjs-chart-boxplot';
import { InternalSettings } from 'datatables.net';
import { faTableCells } from '@fortawesome/free-solid-svg-icons';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { PortalWindowComponent } from '@app/@shared/portal-window.component';
import { environment } from '@env/environment';

interface summaryData {
  desc: string;
  cols: { title: string; data: string }[];
  data: { [key: string]: any }[];
}

interface summaryRow {
  sample: string;
  locus: string;
  shannon: string;
  simpson: string;
}

@Component({
  selector: 'flairr-summary',
  templateUrl: './flairr-summary.component.html',
  styleUrl: './flairr-summary.component.scss',
})
export class FlairrSummaryComponent implements OnInit, AfterViewInit {
  isLoading = false;
  public projectViewData: summaryData = { desc: '', cols: [], data: [] };
  public selectedProjectID: string = '';
  showPortal = false;
  @ViewChild('myPortal') public myPortal!: PortalWindowComponent;
  public removeEventListener!: () => void;

  summaryTable: any = null;
  searchActive: boolean = false;
  compareSelectedRows: boolean = false;
  shannonPlotData: any = null;
  simpsonPlotData: any = null;
  plotData: any = null;

  dtOptions: any = {
    lengthMenu: [
      [10, 100, 250, 500, -1],
      [10, 100, 250, 500, 'All'],
    ],
    data: [{ id: 1 }, { id: 2 }],
    columns: [
      {
        title: 'ID',
        data: 'id',
      },
    ],
    layout: {
      topStart: {
        searchBuilder: {},
        buttons: ['copy', 'excel', 'pdf'],
      },
    },
    drawCallback: this.tableUpdateHandler.bind(this),
    /* rowCallback: (row: Node, data: summaryRow | Object, index: number) => {
      const self = this;
      // Unbind first in order to avoid any duplicate handler
      // (see https://github.com/l-lin/angular-datatables/issues/87)
      // Note: In newer jQuery v3 versions, `unbind` and `bind` are
      // deprecated in favor of `off` and `on`
      $('td:last', row).off('click');
      $('td:last', row).on('click', () => {
        self.rowCloneMapCellClickHandler(row, data);
      });
      return row;
    }, */
  };

  chartConfig: ChartConfiguration<'violin'>['data'] | undefined;

  chartOptions: ChartConfiguration<'violin'>['options'] = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: true,
    elements: {},
    scales: {
      x: {
        display: true,
        title: { text: '', display: false },
      },
      y: {
        display: true,
      },
    },
  };

  constructor(
    private backendService: BackendService,
    public projectService: ProjectService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    effect(() => {
      this.setProject();
    }, {});
  }

  ngOnInit(): void {
    Chart.register(BoxPlotController, BoxAndWiskers, ViolinController, Violin, LinearScale, CategoryScale);
    library.add(faTableCells);

    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      const attr = event.target.getAttribute('data-link');
      if (attr) {
        this.cellClickHandler(event);
      }
    });
  }

  ngAfterViewInit(): void {}

  // Haplotype / novel allele charts

  // Project / locus selection

  setProject() {
    if (this.projectService.selectedProjectDetails() !== null) {
      const projectDetails = this.projectService.selectedProjectDetails();
      this.selectedProjectID = projectDetails ? projectDetails.project_id : '';

      console.log('setting project: ', this.selectedProjectID);

      this.isLoading = true;
      console.log('getting project view data');
      this.backendService.getProjectSummaryData(this.selectedProjectID, 'FLAIRR-Seq').subscribe((response: any) => {
        if (response.status === 200) {
          this.isLoading = false;
          this.projectViewData = response.reply;

          this.projectViewData['data'].forEach((row: any) => {
            const links = row['clone_map'];
            row[
              'clone_map'
            ] = `<div class="dropdown" ><button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">${icon(
              faTableCells
            ).html.join('')}</button><ul class="dropdown-menu">${links}</ul></div>`;
          });

          if (Object.keys(this.projectViewData).length > 0) {
            this.selectAnalysis();
          }
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  selectAnalysis() {
    if (this.summaryTable != null) {
      this.summaryTable.destroy('true');
      $('#summary_table_holder').append('<table id="summary_table"></table>');
    }

    this.dtOptions.columns = this.projectViewData.cols;
    this.dtOptions.data = this.projectViewData.data;
    this.summaryTable = $('#summary_table').DataTable(this.dtOptions);
    console.log('set analysis');
    this.tableUpdateHandler(null);
  }

  tableUpdateHandler(settings: InternalSettings | null) {
    console.log('table updated');
    if (this.summaryTable) {
      console.log('data in table');
      const dtInstance = this.summaryTable;
      const rows = dtInstance.rows({ search: 'applied' }).data().toArray();
      const unselectedRows = dtInstance.rows({ search: 'removed' }).data().toArray();
      const loci: string[] = [];
      this.searchActive = unselectedRows.length > 0;

      rows.forEach((row: summaryRow) => {
        if (!loci.includes(row.locus)) {
          loci.push(row.locus);
        }
      });

      if (this.searchActive && this.compareSelectedRows) {
        unselectedRows.forEach((row: summaryRow) => {
          if (!loci.includes(row.locus)) {
            loci.push(row.locus);
          }
        });
      }

      loci.sort();

      const plotData = this.generatePlotData(loci, rows, unselectedRows);

      this.shannonPlotData = plotData.shannon;
      this.simpsonPlotData = plotData.simpson;
    }
  }

  generatePlotData(loci: string[], rows: summaryRow[], unselectedRows: summaryRow[]) {
    const plotData: any = { shannon: { labels: loci, datasets: [] }, simpson: { labels: loci, datasets: [] } };

    const selectedData = this.extractDataByLocus(loci, rows);
    const firstlabel = this.searchActive ? 'Selected' : 'All';
    plotData.shannon.datasets.push({ label: firstlabel, data: selectedData.shannon });
    plotData.simpson.datasets.push({ label: firstlabel, data: selectedData.simpson });

    if (this.searchActive && this.compareSelectedRows) {
      const unselectedData = this.extractDataByLocus(loci, unselectedRows);
      plotData.shannon.datasets.push({ label: 'Unselected', data: unselectedData.shannon });
      plotData.simpson.datasets.push({ label: 'Unselected', data: unselectedData.simpson });
    }

    return plotData;
  }

  extractDataByLocus(loci: string[], rows: summaryRow[]) {
    const data: any = { shannon: [], simpson: [] };

    loci.forEach((locus: string) => {
      const shannonVals: string[] = [];
      const simpsonVals: string[] = [];
      rows.forEach((row: summaryRow) => {
        if (row.locus === locus) {
          shannonVals.push(row.shannon);
          simpsonVals.push(row.simpson);
        }
      });
      data.shannon.push(shannonVals);
      data.simpson.push(simpsonVals);
    });

    return data;
  }

  onCompareSelectedRows(event: any) {
    this.compareSelectedRows = event.target.checked;
    console.log('compare selected rows: ' + this.compareSelectedRows);
    this.tableUpdateHandler(null);
  }

  cellClickHandler(event: any) {
    let value = event.target.getAttribute('data-link');
    const dtype = event.target.getAttribute('data-type');
    const sample = event.target.getAttribute('data-sample');
    const text = event.target.innerText;

    if (
      dtype === 'png' ||
      dtype === 'svg' ||
      dtype === 'gif' ||
      dtype === 'jpg' ||
      dtype === 'jpeg' ||
      dtype === 'txt'
    ) {
      const externalWindow = window.open('', '_blank');
      document.querySelectorAll('link, style').forEach((htmlElement) => {
        externalWindow!.document.head.appendChild(htmlElement.cloneNode(true));
      });

      // set current theme from local storage

      const theme = localStorage.getItem('theme');
      if (theme) {
        externalWindow!.document.documentElement.setAttribute('data-bs-theme', theme);
        if (theme === 'dark') {
          externalWindow!.document.documentElement.classList.add('dark');
        } else {
          externalWindow!.document.documentElement.classList.remove('dark');
        }
      }

      externalWindow!.document.title = `${sample} - ${text}`;

      if (dtype !== 'txt') {
        externalWindow!.document.body.innerHTML = `<div class="container-fluid" style="width: 100%; padding-left: 40px; padding-right: 40px; padding-top: 25px">
        <h2>${text} for sample ${sample}</h2>
        <img src="${environment.serverUrl}${value}" style="border: none;"></img>
        </div>`;
      } else {
        // fetch data from server and display in code block
        value = value.replace('s3_redirect/', '');
        this.backendService.getS3Content(value).subscribe((response: any) => {
          if (response.status == 200) {
            // fetch data from response.link and display in code block
            externalWindow!.document.body.innerHTML = `<div class="container-fluid" style="width: 100%; padding-left: 40px; padding-right: 40px; padding-top: 25px">
            <h2>${text} for sample ${sample}</h2>
            <pre><code>${response.reply.content}</code></pre>
            </div>`;
          }
        });
      }
    } else {
      window.open(environment.serverUrl + value, '_blank');
    }
  }
}
