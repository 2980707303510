import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { retryWithBackoff } from './retry_with_backoff';

export interface backendMessageResponse {
  message: string;
  status: string;
  error: string;
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public defaultHeaders = new HttpHeaders();

  constructor(private httpClient: HttpClient) {}

  callBackend(path: string): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', 'application/json');

    // cookies will not be set unless withCredentials is set to true
    console.log('calling httpclient');
    return this.httpClient.get(environment.serverUrl + path, { withCredentials: true });
  }

  callBackendWithRetry(path: string): Observable<any> {
    return this.callBackend(path).pipe(
      retryWithBackoff(),
      catchError((error) => {
        console.log(error);
        return of({ message: null, status: error.status, error: error.message });
      }),
      map((body: any) => body)
    );
  }

  callProtectedRoute(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('protected_route');
  }

  callUserProtectedRoute(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('protected_route_user');
  }

  getUserProjectDetails(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('get_user_project_details');
  }

  getSampleFiles(projectId: string, sampleId: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_sample_files/${projectId}/${sampleId}`);
  }

  getProjectViewData(projectId: string, assayType: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_view_data/${projectId}/${assayType}`);
  }

  getProjectGeneData(projectId: string, assayType: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_gene_data/${projectId}/${assayType}`);
  }

  getS3Link(path: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`s3_link/${path}`);
  }

  getS3Content(path: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`s3_content/${path}`);
  }

  refreshData(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('refresh_data');
  }

  getProjectAlleleUsage(projectId: string, assayType: string, locus: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_allele_usage/${projectId}/${assayType}/${locus}`);
  }

  getProjectQCData(projectId: string, assayType: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_qc_data/${projectId}/${assayType}`);
  }

  getProjectSummaryData(projectId: string, assayType: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_summary_data/${projectId}/${assayType}`);
  }

  getProjectSampleDisplayFiles(
    projectId: string,
    sampleId: string,
    assayType: string
  ): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_sample_display_files/${projectId}/${sampleId}/${assayType}`);
  }
}
