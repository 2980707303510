import { Component, OnInit, AfterViewInit, effect, Input } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { ChartConfiguration } from 'chart.js';
import { ProjectDetails } from '@shared/api_interfaces';
import { ProjectService } from '@app/shell/header/header.project.service';

@Component({
  selector: 'gene-analytics',
  templateUrl: './gene-analytics.component.html',
  styleUrls: ['./gene-analytics.component.scss'],
})
export class GeneAnalyticsComponent implements AfterViewInit {
  public _analysis_type: string = '';
  @Input() set analysis_type(value: string) {
    console.log('setting analysis_type: ' + value);
    this._analysis_type = value;
    this.setProject();
  }
  isLoading = false;
  public projectGeneData: any = null;
  selectedLocus: string = '';
  selectedGene: string = '';
  public projectLoci: string[] = [];
  public projectGenes: string[] = [];
  public barChartPlugins = [];
  public geneOccurrencePlot: any = null;
  public alleleOccurrencePlot: any = null;
  public alleleZygosityPlot: any = null;

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        ticks: { autoSkip: false },
      },
    },
  };

  constructor(private backendService: BackendService, public projectService: ProjectService) {
    effect(() => {
      this.setProject();
    }, {});
  }

  ngAfterViewInit(): void {
    console.log('gene-analytics ngAfterViewInit');
  }

  setProject() {
    this.projectGeneData = null;
    this.projectLoci = [];
    this.projectGenes = [];
    this.selectedGene = '';
    this.geneOccurrencePlot = null;
    this.alleleOccurrencePlot = null;
    this.alleleZygosityPlot = null;

    this.isLoading = true;
    console.log('analysis_type: ' + this._analysis_type);
    if (this.projectService.selectedProjectDetails() !== null) {
      const projectDetails = this.projectService.selectedProjectDetails();
      const selectedProjectID: string = projectDetails ? projectDetails.project_id : '';
      this.backendService.getProjectGeneData(selectedProjectID, this._analysis_type).subscribe((response: any) => {
        if (response.status === 200) {
          this.projectGeneData = response;
          this.projectLoci = this.projectGeneData.loci;

          if (this.projectLoci.length > 0) {
            this.selectLocus(this.projectLoci[0]);
          }
        }
        this.isLoading = false;
      });
    }
  }

  onSelectedLocus(event: any) {
    this.selectLocus(event.target.value);
  }

  selectLocus(locus: string) {
    this.selectedLocus = locus;
    this.projectGenes = Object.keys(this.projectGeneData.allele_occurrence_plots[this.selectedLocus]);

    if (this.projectGenes.length > 0) {
      this.selectGene(this.projectGenes[0]);
    } else {
    }
  }

  onSelectedGene(event: any) {
    this.selectGene(event.target.value);
  }

  selectGene(gene: string) {
    console.log('selecting gene ' + gene);
    this.selectedGene = gene;
    this.geneOccurrencePlot = this.projectGeneData.observed_gene_plots[this.selectedLocus][this.selectedGene];
    this.alleleOccurrencePlot = this.projectGeneData.allele_occurrence_plots[this.selectedLocus][this.selectedGene];
    this.alleleZygosityPlot = this.projectGeneData.zygosity_plots[this.selectedLocus][this.selectedGene];
  }
}
