import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, effect, TemplateRef } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { environment } from '@env/environment';
import {
  faFilePdf,
  faFileExcel,
  faFileText,
  faFileWord,
  faFileImage,
  faFileZipper,
  faCaretSquareDown,
} from '@fortawesome/free-regular-svg-icons';
import { faDesktop, faFileCircleQuestion, faCopy } from '@fortawesome/free-solid-svg-icons';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { SampleDetails } from '@shared/api_interfaces';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '@app/shell/header/header.project.service';

// Icons used in child rows of the table
const iconLookup = {
  '.xlsx': faFileExcel,
  '.xls': faFileExcel,
  '.pdf': faFilePdf,
  '.txt': faFileText,
  '.docx': faFileWord,
  '.doc': faFileWord,
  '.html': faDesktop,
  '.png': faFileImage,
  '.fastq': faFileText,
  '.zip': faFileZipper,
  '.gz': faFileZipper,
  '.tsv': faFileExcel,
  '.tab': faFileExcel,
  '.csv': faFileExcel,
  '.vcf': faFileText,
  '.bam': faFileZipper,
  '.bai': faFileText,
  '.log': faFileText,
  '.stats': faFileText,
  '.flagstats': faFileText,
  '.fasta': faFileText,
};

@Component({
  selector: 'app-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.scss'],
})
export class ProjectFilesComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('content') content!: TemplateRef<any>;
  dataTable: any = null;
  dtOptions: any = {};
  faCopy = faCopy;
  isLoading = false;
  modalRef!: BsModalRef;
  projectFiles: any[] = [];

  constructor(
    private backendService: BackendService,
    private modalService: BsModalService,
    public projectService: ProjectService
  ) {
    effect(() => {
      this.setproject();
    }, {});
  }

  ngOnInit(): void {
    library.add(faFilePdf);
    library.add(faFileExcel);
    library.add(faDesktop);
    library.add(faFileWord);
    library.add(faFileImage);
    library.add(faFileText);
    library.add(faFileZipper);
    this.dtOptions = {
      lengthMenu: [
        [100, 250, 500, -1],
        [100, 250, 500, 'All'],
      ],
      data: [{ id: 1 }],
      columns: [
        {
          title: 'ID',
          data: 'id',
        },
      ],

      rowCallback: (row: Node, data: SampleDetails | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // Note: In newer jQuery v3 versions, `unbind` and `bind` are
        // deprecated in favor of `off` and `on`
        $('td:first', row).off('click');
        $('td:first', row).on('click', () => {
          self.rowFirstCellClickHandler(row, data);
        });
        return row;
      },
    };
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  copyLinkToClipboard(path: string) {
    this.backendService.getS3Link(path).subscribe((response: any) => {
      console.log(response);
      if (response.status === 200) {
        navigator.clipboard.writeText(response.url);

        this.modalRef = this.modalService.show(this.content);
      }
    });
  }

  setproject() {
    console.log('setting current project');
    this.projectFiles = [];

    if (this.projectService.selectedProjectDetails() != null) {
      const project = this.projectService.selectedProjectDetails();
      let cols = project?.sample_cols || [];
      if (!cols[0].defaultContent) {
        cols.unshift({
          defaultContent: icon(faCaretSquareDown).html.join(''),
        });
      }
      this.dtOptions.columns = cols;

      project?.files.forEach((file: any) => {
        let file_icon = faFileCircleQuestion;
        if (file.file_ext in iconLookup) {
          file_icon = iconLookup[file.file_ext as keyof typeof iconLookup];
        }
        this.projectFiles.push({
          file_name: file.filename,
          file_red: environment.serverUrl + file.url,
          file_url: file.url.replace('s3_redirect/', ''),
          file_icon: file_icon,
        });
      });

      if (project) {
        console.log('recreating table');
        if (this.dataTable != null) {
          this.dataTable.destroy('true');
          $('#project_files_table_holder').append('<table id="project_files_table"></table>');
        }

        this.dtOptions.data = Object.values(project.samples);
        this.dataTable = $('#project_files_table').DataTable(this.dtOptions);
      }
    }
  }

  rowFirstCellClickHandler(tr: any, data: any) {
    const dtInstance = this.dataTable;
    let row = dtInstance.row(tr);

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
    } else {
      if (this.projectService.selectedProjectDetails()) {
        this.isLoading = true;
        this.backendService
          .getSampleFiles(this.projectService.selectedProjectDetails()?.project_id ?? '', data.sample_id)
          .subscribe((response: any) => {
            if (response.file_list) {
              var child_text = '';

              child_text = this.format(response.file_list);

              if (response.file_list['files'].length > 0) {
                child_text += '<h6>Entire sample</h6>';
                child_text += this.format(response.file_list['files']);
              }

              for (var locus in response.file_list['loci']) {
                child_text += '<h6>' + locus + '</h6>';
                child_text += this.format(response.file_list['loci'][locus]);
              }

              row.child(child_text).show();
            }
            this.isLoading = false;
          });
      }
    }
  }

  format(l: any, title: string = '', level: number = 0) {
    let file_descs = '';
    let px = 40 * level;

    if ('files' in l) {
      file_descs += `<div style="padding-left: ${px}px;">`;
      if (title.length > 0) {
        file_descs += `<h6>${title}</h6>`;
      } else {
        file_descs += `<h6>Entire sample</h6>`;
      }

      if (l['files'].length > 0) {
        file_descs += `<div class="row"><ul class="list-inline">`;
        l['files'].forEach((file_row: any) => {
          let file_icon = faFileCircleQuestion;
          if (file_row[1] in iconLookup) {
            file_icon = iconLookup[file_row[1] as keyof typeof iconLookup];
          }
          file_descs += `<li ><a href="${environment.serverUrl + file_row[2]}">${icon(file_icon).html.join(
            ''
          )}</a><span>&nbsp;&nbsp;</span>${file_row[0]}</li>`;
        });
        file_descs += '</ul></div>';
      }
      file_descs += '</div>';
    }

    let lv = level + 1;
    if ('sublevel' in l) {
      for (var sl in l['sublevel']) {
        file_descs += this.format(l['sublevel'][sl], sl, lv);
      }
    }

    return file_descs;
  }
}
