import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { Logger } from '../@shared/logger.service';
import { CredentialsService } from './credentials.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public userName: string | undefined;
  public userEmail: string | undefined;
  public loggedIn = false;

  constructor() {}

  ngOnInit() {}
}
