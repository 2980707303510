import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

import { AlleleUsageComponent } from './allele-usage.component';

const routes: Routes = [
  Shell.publicRoutes([{ path: 'allele-usage', component: AlleleUsageComponent, data: { title: 'AlleleUsage' } }]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AlleleUsageRoutingModule {}
