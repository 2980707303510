import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from, switchMap, of } from 'rxjs';
import { Auth } from 'aws-amplify';
import { CredentialsService } from '@app/auth/credentials.service';

import { environment } from '@env/environment';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl` and adds the JWT if we have one.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService) {}

  getAccessToken(request: HttpRequest<any>): Observable<string | null> {
    if (this.credentialsService.credentials() && request.url.startsWith(environment.serverUrl)) {
      return from(Auth.currentSession()).pipe(switchMap((session) => of(session.getAccessToken().getJwtToken())));
    } else {
      return of(null);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('AuthInterceptor: intercept');
    return this.getAccessToken(request).pipe(
      switchMap((token) => {
        var authReq = request.clone();
        if (token) {
          authReq = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });
        } else {
          authReq = request.clone();
        }
        let ret = next.handle(authReq);
        console.log('AuthInterceptor: returning');
        return ret;
      })
    );
  }
}
