import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';

import { SharedModule } from '@shared';
import { GeneAnalyticsRoutingModule } from './gene-analytics-routing.module';
import { GeneAnalyticsComponent } from './gene-analytics.component';

@NgModule({
  imports: [CommonModule, SharedModule, NgChartsModule, GeneAnalyticsRoutingModule],
  declarations: [GeneAnalyticsComponent],
})
export class GeneAnalyticsModule {}
