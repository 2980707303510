// From https://github.com/ng-bootstrap/ng-bootstrap (MIT licence)

import { ChangeDetectionStrategy, Component, computed, effect, signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSun, faMoon, IconDefinition, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';

interface Theme {
  id: 'auto' | 'light' | 'dark';
  name: string;
  icon: IconDefinition;
}

const PREFERS_COLOR_SCHEME_DARK = window.matchMedia('(prefers-color-scheme: dark)');

const THEMES: Theme[] = [
  { id: 'auto', name: 'Auto', icon: faCircleHalfStroke },
  { id: 'light', name: 'Light', icon: faSun },
  { id: 'dark', name: 'Dark', icon: faMoon },
];

@Component({
  selector: 'ngbd-theme-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle text-white"
        href="#"
        id="themeDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <fa-icon [icon]="current().icon"></fa-icon>
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="themeDropdown">
        @for (theme of themes(); track theme) {
        <button class="dropdown-item" [class.active]="theme.id === current().id" (click)="preferred.set(theme.id)">
          <fa-icon [icon]="theme.icon"></fa-icon>&nbsp;&nbsp;&nbsp;{{ theme.name }}
        </button>
        }
      </ul>
    </li>
  `,
})
export class ThemePickerComponent {
  themes = signal(THEMES).asReadonly();
  preferred = signal(localStorage.getItem('theme') || this.themes()[0].id);
  current = computed(() => this.themes().find((t) => t.id === this.preferred()) || this.themes()[0]);
  systemTheme = signal(PREFERS_COLOR_SCHEME_DARK.matches ? 'dark' : 'light');
  dataBsTheme = computed(() => (this.current().id === 'auto' ? this.systemTheme()! : this.current().id));

  constructor() {
    // save preferred theme in local storage when it changes
    effect(() => localStorage.setItem('theme', this.preferred()));

    // updating current theme in DOM
    effect(() => {
      document.documentElement.setAttribute('data-bs-theme', this.dataBsTheme());

      if (this.dataBsTheme() === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    });

    // listening to system theme changes, could be done with RxJS, but this is simpler
    PREFERS_COLOR_SCHEME_DARK.addEventListener('change', (event) =>
      this.systemTheme.set(event.matches ? 'dark' : 'light')
    );
  }
}
