<div class="container-fluid" style="width: 100%; padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <app-loader [isLoading]="isLoading"></app-loader>

  <h2>Allele Usage in repertoires</h2>

  <div *ngIf="projectViewData">
    <div class="row p-3 justify-content-md-center">
      <div class="row p-3">
        <div class="col-2">
          <label>Locus</label>
          <select class="form-select" (change)="onSelectedLocus($event)">
            <option *ngFor="let locus of projectViewData.loci_covered" [value]="locus">
              {{ locus }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <span id="alleleExpressionSpan" [attr.style]="alleleExpressionStyle">
    <div class="row p-3 justify-content-md-center">
      <h3>Allele Expression</h3>
      <div id="usage_table_holder">
        <table id="usage_table"></table>
      </div>
    </div>

    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        [disabled]="!searchActive"
        (change)="onCompareSelectedRows($event)"
      />
      <label class="form-check-label" for="flexSwitchCheckDefault">Compare selected and unselected rows</label>
    </div>

    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchViolinPlots"
        (change)="onToggleViolinPlots($event)"
      />
      <label class="form-check-label" for="flexSwitchViolinPlots">Show Violin Plots</label>
    </div>

    <div *ngIf="showViolinPlots">
      <div *ngFor="let gtype of getalleleUsageGeneTypes()">
        <div class="row p-12 justify-content-md-center">
          {{ gtype }}
          <div *ngIf="alleleUsagePlotData" class="col-md-auto" [attr.style]="getalleleUsageCanvasStyle(gtype)">
            <canvas
              baseChart
              [data]="alleleUsagePlotData[gtype]"
              [options]="violinChartAlleleUsageOptions"
              [legend]="false"
              [type]="'violin'"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!showViolinPlots">
      <div *ngFor="let gtype of getalleleUsageGeneTypes()">
        <div class="row p-12 justify-content-md-center">
          {{ gtype }}
          <div *ngIf="alleleUsagePlotData" class="col-md-auto" [attr.style]="getalleleUsageCanvasStyle(gtype)">
            <canvas
              baseChart
              [data]="alleleUsagePlotData[gtype]"
              [options]="barChartAlleleUsageOptions"
              [legend]="false"
              [type]="'bar'"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </span>
</div>
