import { Injectable, signal, computed } from '@angular/core';
import { Router } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { Logger } from '../@shared/logger.service';

export interface Credentials {
  username: string;
  email: string;
  groups: string[];
}

const logger = new Logger('My-Logger');

const logAuthEvents = (data: any) => {
  switch (data.payload.event) {
    case 'configured':
      logger.info('the Auth module is configured');
      break;
    case 'signIn':
      logger.info('user signed in');
      break;
    case 'signIn_failure':
      logger.error('user sign in failed');
      break;
    case 'signUp':
      logger.info('user signed up');
      break;
    case 'signUp_failure':
      logger.error('user sign up failed');
      break;
    case 'confirmSignUp':
      logger.info('user confirmation successful');
      break;
    case 'completeNewPassword_failure':
      logger.error('user did not complete new password flow');
      break;
    case 'autoSignIn':
      logger.info('auto sign in successful');
      break;
    case 'autoSignIn_failure':
      logger.error('auto sign in failed');
      break;
    case 'forgotPassword':
      logger.info('password recovery initiated');
      break;
    case 'forgotPassword_failure':
      logger.error('password recovery failed');
      break;
    case 'forgotPasswordSubmit':
      logger.info('password confirmation successful');
      break;
    case 'forgotPasswordSubmit_failure':
      logger.error('password confirmation failed');
      break;
    case 'verify':
      logger.info('TOTP token verification successful');
      break;
    case 'tokenRefresh':
      logger.info('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      logger.error('token refresh failed');
      break;
    case 'cognitoHostedUI':
      logger.info('Cognito Hosted UI sign in successful');
      break;
    case 'cognitoHostedUI_failure':
      logger.error('Cognito Hosted UI sign in failed');
      break;
    case 'customOAuthState':
      logger.info('custom state returned from CognitoHosted UI');
      break;
    case 'customState_failure':
      logger.error('custom state failure');
      break;
    case 'parsingCallbackUrl':
      logger.info('Cognito Hosted UI OAuth url parsing initiated');
      break;
    case 'userDeleted':
      logger.info('user deletion successful');
      break;
    case 'updateUserAttributes':
      logger.info('user attributes update successful');
      break;
    case 'updateUserAttributes_failure':
      logger.info('user attributes update failed');
      break;
    case 'signOut':
      logger.info('user signed out');
      break;
  }
};

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials = signal<Credentials | null>(null);
  public readonly credentials = this._credentials.asReadonly();
  public readonly username = computed(() => {
    let ret = '';
    if (this.credentials() !== null) {
      ret = this.credentials()!.username;
    }

    return ret;
  });

  public readonly email = computed(() => {
    let ret = '';
    if (this.credentials() !== null) {
      ret = this.credentials()!.email;
    }

    return ret;
  });

  public readonly admin = computed(() => {
    let ret = false;
    if (this.credentials() !== null) {
      ret = this.credentials()!.groups.includes('admin');
    }

    return ret;
  });

  constructor(private router: Router) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('currentAuthenticatedUser: ' + user.username);
        this._credentials.set({
          username: user.username,
          email: user.attributes.email,
          groups: user.signInUserSession.accessToken.payload['cognito:groups'],
        });
      })
      .catch((err) => {
        console.log('error currentAuthenticatedUser: ' + err);
        this._credentials.set(null);
      });
    Hub.listen('auth', this.listener);
  }

  listener = (data: any) => {
    logAuthEvents(data);
    switch (data.payload.event) {
      case 'signIn':
        console.log('user signed in');
        this._credentials.set({
          username: data.payload.data.username,
          email: data.payload.data.signInUserSession.idToken.payload.email,
          groups: data.payload.data.signInUserSession.accessToken.payload['cognito:groups'],
        });
        this.router.navigate(['/home']);
        break;
      case 'signOut':
        console.log('user signed out');
        this._credentials.set(null);
        this.router.navigate(['/home']);
        break;
    }
  };
}
