<div class="container-fluid" style="width: 100%; padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <app-loader [isLoading]="isLoading"></app-loader>

  <h2>Sample Reads and QC</h2>

  <div *ngIf="projectViewData">
    <div class="row p-3 justify-content-md-center">
      <div class="row p-3">
        <div class="col-2">
          <label>Analysis Type</label>
          <select class="form-select" (change)="onSelectedAnalysis($event)">
            <option *ngFor="let entry of projectViewData | keyvalue" [value]="entry.key">
              {{ entry.value.desc }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-3 justify-content-md-center">
    <div id="stats_table_holder">
      <table id="stats_table"></table>
    </div>
  </div>
</div>
