<div class="container-fluid">
  <div class="row justify-content-sm-center" style="width: 100%">
    <div class="col-sm-6">
      <div class="card text-center center-me border border-0">
        <div class="card-body">
          <h5 class="card title border border-0">
            <img src="assets/clareo-logo.svg" alt="Clareo logo" style="height: 300px" />
          </h5>
          <div *ngIf="!credentialsService.admin()">Welcome</div>
          <div *ngIf="credentialsService.admin()">Welcome, Admin</div>
          <div *ngIf="credentialsService.credentials()">
            <span>You are logged in as {{ credentialsService.email() }}</span>
          </div>
          <div *ngIf="!credentialsService.credentials()">
            <button type="button" class="btn btn-outline-primary btn-sm" routerLink="/login">Login</button>
          </div>
          <div *ngIf="credentialsService.admin()">
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="refreshData()">Refresh data</button>
            <div *ngIf="refreshText">
              <span>{{ refreshText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
