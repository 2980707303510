import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { FlairrStatsRoutingModule } from './flairr-stats-routing.module';
import { FlairrStatsComponent } from './flairr-stats.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared';

@NgModule({
  imports: [CommonModule, FlairrStatsRoutingModule, DataTablesModule, BrowserModule, SharedModule],
  declarations: [FlairrStatsComponent],
})
export class FlairrStatsModule {}
