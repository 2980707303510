<div class="container" style="padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <app-loader [isLoading]="isLoading"></app-loader>
  <h2>Single Gene Observations</h2>
  <div class="col-2">
    <label>Locus</label>
    <select class="form-select" (change)="onSelectedLocus($event)">
      <option *ngFor="let locus of projectLoci" [value]="locus">
        {{ locus }}
      </option>
    </select>
  </div>

  <div class="col-2">
    <label>Gene</label>
    <select class="form-select" (change)="onSelectedGene($event)">
      <option *ngFor="let gene of projectGenes" [value]="gene">
        {{ gene }}
      </option>
    </select>
  </div>

  <div *ngIf="projectGeneData">
    <div class="row p-3 justify-content-md-center">
      <h3>Gene observations by subject</h3>

      <div class="col-md-auto" style="width: 600px">
        <canvas
          baseChart
          [data]="geneOccurrencePlot"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="true"
          [type]="'bar'"
        >
        </canvas>
      </div>

      <div class="row p-3 justify-content-md-center">
        <h3>Allele zygosity by subject</h3>

        <div class="col-md-auto" style="width: 600px">
          <canvas
            baseChart
            [data]="alleleZygosityPlot"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="true"
            [type]="'bar'"
          >
          </canvas>
        </div>

        <div class="row p-3 justify-content-md-center">
          <h3>Allele occurrences by subject</h3>

          <div class="col-md-auto" style="width: 600px">
            <canvas
              baseChart
              [data]="alleleOccurrencePlot"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="true"
              [type]="'bar'"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
