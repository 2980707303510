import { Component, OnInit, AfterViewInit, ViewChild, effect } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { Chart, ChartConfiguration, LinearScale, CategoryScale } from 'chart.js';
import { Subject } from 'rxjs';
import { InternalSettings } from '../../../node_modules/datatables.net';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-buttons-dt';
import { ProjectService } from '@app/shell/header/header.project.service';
import { BoxPlotController, ViolinController, Violin } from '@sgratzl/chartjs-chart-boxplot';

// Build stacked barchart data for allele usage
interface AlleleUsageRow {
  gene: string;
  gene_type: string;
  vdjbase_allele: string;
  frequency: string;
  sample_name: string;
}

interface BarDataset {
  label: string;
  data: number[];
  stack: string;
}

interface BarData {
  labels: string[];
  datasets: BarDataset[];
}

interface ViolinDataset {
  label: string;
  data: number[][];
}

interface ViolinData {
  labels: string[];
  datasets: ViolinDataset[];
}

// I had unresolved issues with button rendering when trying to use datatables bs5 styling
// there are apparent missing declarations in the datatables.net-bs5 css

@Component({
  selector: 'allele-usage',
  templateUrl: './allele-usage.component.html',
  styleUrl: './allele-usage.component.scss',
})
export class AlleleUsageComponent implements OnInit, AfterViewInit {
  isLoading = false;
  public projectViewData: any = null;
  selectedLocus: string = '';
  public barChartPlugins = [];
  public selectedProjectID: string = '';
  usageTable: any = null;

  // Allele Usage table and chart
  showAlleleUsage = true;
  searchActive = false;
  compareSelectedRows = false;
  showViolinPlots = false;
  alleleExpressionStyle = 'display: none;';
  public alleleUsagePlotData: any = null;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {
    lengthMenu: [
      [10, 100, 250, 500, -1],
      [10, 100, 250, 500, 'All'],
    ],
    data: [],
    columns: [
      {
        title: 'ID',
        data: 'id',
      },
    ],
    layout: {
      topStart: {
        searchBuilder: {},
        buttons: ['copy', 'excel', 'pdf'],
      },
    },
    drawCallback: this.alleleUsageUpdateHandler.bind(this), // .bind is needed to make the callback work in the context of this class
  };
  public graph = {
    data: [
      { x: [1, 2, 3], y: [2, 6, 3], type: 'scatter', mode: 'lines+points', marker: { color: 'red' } },
      { x: [1, 2, 3], y: [2, 5, 3], type: 'bar' },
    ],
    layout: { width: 320, height: 240, title: 'A Fancy Plot' },
  };

  constructor(private backendService: BackendService, public projectService: ProjectService) {
    effect(() => {
      this.setProject();
    }, {});
  }

  ngOnInit(): void {
    Chart.register(BoxPlotController, ViolinController, Violin, LinearScale, CategoryScale);
  }

  ngAfterViewInit(): void {}

  // Haplotype / novel allele charts

  // Project / locus selection

  setProject() {
    if (this.projectService.selectedProjectDetails() !== null) {
      const projectDetails = this.projectService.selectedProjectDetails();
      this.selectedProjectID = projectDetails ? projectDetails.project_id : '';

      this.isLoading = true;
      console.log('getting project view data');
      this.backendService.getProjectViewData(this.selectedProjectID, 'FLAIRR-Seq').subscribe((response: any) => {
        if (response.status === 200) {
          console.log('got project view data');
          this.isLoading = false;
          this.projectViewData = response;
          this.selectLocus(this.projectViewData.loci_covered[0]);
          console.log('back from select locus');
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  selectLocus(locus: string) {
    this.onSelectedLocus({ target: { value: locus } });
  }

  onSelectedLocus(event: any) {
    this.alleleUsageUpdate(event);
  }

  onCompareSelectedRows(event: any) {
    this.compareSelectedRows = event.target.checked;
    console.log('compare selected rows: ' + this.compareSelectedRows);
    this.alleleUsageUpdateHandler(null);
  }

  onToggleViolinPlots(event: any) {
    this.showViolinPlots = event.target.checked;
    console.log('show violin plots: ' + this.showViolinPlots);
    this.alleleUsageUpdateHandler(null);
  }

  // Allele Usage table and chart

  // Function called from ngAfterViewInit
  alleleUsageViewInit() {
    this.dtTrigger.next(null);
  }

  // Function called when the project or locus changes
  alleleUsageUpdate(event: any) {
    console.log('alleleUsageUpdate');
    this.selectedLocus = event.target.value;

    this.isLoading = true;
    this.backendService
      .getProjectAlleleUsage(this.selectedProjectID, 'FLAIRR-Seq', this.selectedLocus)
      .subscribe((response: any) => {
        this.isLoading = false;

        if (response.status === 200) {
          this.dtOptions.columns = response.cols;
          this.dtOptions.data = response.data;

          if (this.usageTable != null) {
            this.usageTable.destroy('true');
            $('#usage_table_holder').append('<table id="usage_table"></table>');
          }

          this.usageTable = $('#usage_table').DataTable(this.dtOptions);
          this.showAlleleUsage = true;
          this.alleleUsageUpdateHandler(null);

          console.log('changed locus');
          this.alleleExpressionStyle = 'display: block;';
        } else {
          this.alleleExpressionStyle = 'display: none;';
        }
      });
  }

  // handler called when data in table changes
  // this is within the class context because of the .bind() in dtOptions/drawCallback
  alleleUsageUpdateHandler(settings: InternalSettings | null) {
    console.log('draw callback');

    if (this.usageTable) {
      const dtInstance = this.usageTable;
      const rows = dtInstance.rows({ search: 'applied' }).data();
      const unselectedRows = dtInstance.rows({ search: 'removed' }).data();

      this.searchActive = unselectedRows.length > 0;

      if (this.searchActive && this.compareSelectedRows) {
        if (this.showViolinPlots) {
          this.alleleUsagePlotData = this.comparativeAlleleViolinUsageData(
            rows.toArray() as AlleleUsageRow[],
            unselectedRows.toArray() as AlleleUsageRow[]
          );
        } else {
          this.alleleUsagePlotData = this.comparativeAlleleBarUsageData(
            rows.toArray() as AlleleUsageRow[],
            unselectedRows.toArray() as AlleleUsageRow[]
          );
        }
      } else {
        if (this.showViolinPlots) {
          this.alleleUsagePlotData = this.alleleViolinUsageData(rows.toArray() as AlleleUsageRow[]);
        } else {
          this.alleleUsagePlotData = this.alleleBarUsageData(rows.toArray() as AlleleUsageRow[]);
        }
      }
    }
  }

  // --- Generic utilities for manipulating allele data

  // Method to get the keys of alleleUsagePlotData
  getalleleUsageGeneTypes(): string[] {
    return this.alleleUsagePlotData ? Object.keys(this.alleleUsagePlotData) : [];
  }

  // group usage data by locus
  private groupDataByGeneType(data: AlleleUsageRow[]) {
    const dataByGeneType: { [key: string]: AlleleUsageRow[] } = {};
    data.forEach((row) => {
      if (!dataByGeneType[row.gene_type]) {
        dataByGeneType[row.gene_type] = [];
      }
      dataByGeneType[row.gene_type].push(row);
    });
    return dataByGeneType;
  }

  // Method to calculate the canvas style to fit the number of genes
  getalleleUsageCanvasStyle(geneType: string): string {
    if (this.alleleUsagePlotData) {
      let geneCount = this.alleleUsagePlotData[geneType].labels.length;

      if (this.searchActive && this.compareSelectedRows) {
        geneCount *= 2;
      }

      const h = 60 + geneCount * 20;
      console.log(`gene type: ${geneType} height: ${h}px;`);
      return `height: ${h}px; width: 60vw`;
    } else {
      return '';
    }
  }

  // calculate the highest gene number for this gene
  private calculateMaxAllele(geneTypeData: AlleleUsageRow[]) {
    let maxAllele = 0;
    geneTypeData.forEach((row) => {
      const alleleMatch = row.vdjbase_allele.match(/\*(\d+)/);
      if (alleleMatch) {
        const alleleNumber = parseInt(alleleMatch[1], 10);
        if (alleleNumber > maxAllele) {
          maxAllele = alleleNumber;
        }
      }
    });
    return maxAllele;
  }

  // --- Functions for creating bar chart data

  alleleBarUsageData(data: AlleleUsageRow[]) {
    // Create a structure to group data by gene_type
    const dataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(data);

    const result: {
      [key: string]: BarData;
    } = {};

    Object.keys(dataByGeneType).forEach((geneType) => {
      const geneTypeData = dataByGeneType[geneType];

      // Find the maximum allele prefix for this gene type
      let maxAllele = this.calculateMaxAllele(geneTypeData);

      // Create the structure for this gene type
      const genes = [...new Set(geneTypeData.map((row) => row.gene))].sort();
      const datasets = this.buildBarDatasets(geneTypeData, genes, maxAllele);
      const labels = genes;

      result[geneType] = {
        labels,
        datasets,
      };
    });

    return result;
  }

  comparativeAlleleBarUsageData(data: AlleleUsageRow[], removedData: AlleleUsageRow[]) {
    const allData = data.concat(removedData);
    const dataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(data);
    const removedDataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(removedData);
    const allDataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(allData);

    const result: {
      [key: string]: BarData;
    } = {};

    const violinResult: {
      [key: string]: ViolinData;
    } = {};

    Object.keys(dataByGeneType).forEach((geneType) => {
      const allGeneTypeData = allDataByGeneType[geneType];

      // Find the maximum allele prefix for this gene type
      const maxAllele = this.calculateMaxAllele(allGeneTypeData);
      const genes = [...new Set(allGeneTypeData.map((row) => row.gene))].sort();

      // Create the structure for this gene type
      let datasets: BarDataset[] = this.buildBarDatasets(dataByGeneType[geneType], genes, maxAllele, 'Stack 0');
      datasets.push(...this.buildBarDatasets(removedDataByGeneType[geneType], genes, maxAllele, 'Stack 1'));

      const labels = genes;

      result[geneType] = {
        labels,
        datasets,
      };
    });

    return result;
  }

  private buildBarDatasets(
    geneTypeData: AlleleUsageRow[],
    genes: string[],
    maxAllele: number,
    stack: string = 'stack'
  ): BarDataset[] {
    const brewerColors = [
      '#a6cee3',
      '#1f78b4',
      '#b2df8a',
      '#33a02c',
      '#fb9a99',
      '#e31a1c',
      '#fdbf6f',
      '#ff7f00',
      '#cab2d6',
      '#6a3d9a',
      '#ffff99',
      '#b15928',
    ];
    const datasets: BarDataset[] = Array.from({ length: maxAllele }, (_, i) => ({
      label: `*${String(i + 1).padStart(2, '0')}`,
      data: Array(genes.length).fill(0),
      barThickness: 10,
      stack: stack,
      backgroundColor: brewerColors[i % brewerColors.length],
    }));

    // Populate the datasets for this gene type
    this.populateAlleleBarDatasets(geneTypeData, genes, datasets);
    return datasets;
  }

  private populateAlleleBarDatasets(
    geneTypeData: AlleleUsageRow[],
    genes: string[],
    datasets: { label: string; data: any[]; barThickness?: number }[]
  ) {
    geneTypeData.forEach((row) => {
      const geneIndex = genes.indexOf(row.gene);
      const alleleMatch = row.vdjbase_allele.match(/\*(\d+)/);
      if (alleleMatch) {
        const alleleNumber = parseInt(alleleMatch[1], 10);
        datasets[alleleNumber - 1].data[geneIndex] += parseFloat(row.frequency);
      }
    });

    // Count the number of unique samples for this gene type
    const uniqueSamples = new Set(geneTypeData.map((row) => row.sample_name)).size;

    // Divide each value in datasets.data by the number of unique samples
    datasets.forEach((dataset) => {
      dataset.data = dataset.data.map((value) => value / uniqueSamples);
    });
  }

  // Chart options for allele usage
  public barChartAlleleUsageOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: false,
    elements: {},
    scales: {
      x: {
        display: true,
        title: { text: 'Frequency', display: true },
        stacked: true,
      },
      y: {
        display: true,
        stacked: true,
      },
    },
  };

  // --- Functions for creating violin plot data

  private alleleViolinUsageData(data: AlleleUsageRow[]) {
    // Create a structure to group data by gene_type
    const dataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(data);

    const result: {
      [key: string]: ViolinData;
    } = {};

    Object.keys(dataByGeneType).forEach((geneType) => {
      const geneTypeData = dataByGeneType[geneType];

      // Create the structure for this gene type
      const labels = [...new Set(geneTypeData.map((row) => row.gene))].sort();

      const datasets = [
        {
          label: 'Selected',
          data: this.buildAlleleViolinData(geneTypeData, labels),
        },
      ];

      result[geneType] = {
        labels,
        datasets,
      };
    });

    return result;
  }

  private comparativeAlleleViolinUsageData(data: AlleleUsageRow[], removedData: AlleleUsageRow[]) {
    const allData = data.concat(removedData);
    const dataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(data);
    const removedDataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(removedData);
    const allDataByGeneType: { [key: string]: AlleleUsageRow[] } = this.groupDataByGeneType(allData);

    const result: {
      [key: string]: ViolinData;
    } = {};

    Object.keys(dataByGeneType).forEach((geneType) => {
      const allGeneTypeData = allDataByGeneType[geneType];
      const geneTypeData = dataByGeneType[geneType];
      const removedGeneTypeData = removedDataByGeneType[geneType];

      // Create the structure for this gene type
      const labels = [...new Set(allGeneTypeData.map((row) => row.gene))].sort();

      const datasets = [
        {
          label: 'Selected',
          data: this.buildAlleleViolinData(geneTypeData, labels),
        },
        {
          label: 'Unselected',
          data: this.buildAlleleViolinData(removedGeneTypeData, labels),
        },
      ];

      result[geneType] = {
        labels,
        datasets,
      };
    });

    return result;
  }

  private buildAlleleViolinData(geneTypeData: AlleleUsageRow[], genes: string[]) {
    const data: number[][] = Array.from({ length: genes.length }, () => []);

    geneTypeData.forEach((row) => {
      console.log(row);
      const geneIndex = genes.indexOf(row.gene);
      data[geneIndex].push(parseFloat(row.frequency));
    });

    return data;
  }

  public violinChartAlleleUsageOptions: ChartConfiguration<'violin'>['options'] = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: false,
    elements: {
      violin: {
        itemRadius: 2,
        itemHitRadius: 4,
        itemBackgroundColor: 'black',
        itemBorderColor: 'white',
      },
    },
    scales: {
      x: {
        display: true,
        title: { text: 'Frequency', display: true },
      },
      y: {
        display: true,
      },
    },
  };
}
