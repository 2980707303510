import { AfterViewInit, Component, effect, OnInit } from '@angular/core';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-buttons-dt';
import { BackendService } from '@app/@shared/backend.service';
import { ProjectService } from '@app/shell/header/header.project.service';

interface qcData {
  [key: string]: {
    desc: string;
    cols: { title: string; data: string }[];
    data: { [key: string]: any }[];
  };
}

@Component({
  selector: 'flairr-stats',
  templateUrl: './flairr-stats.component.html',
  styleUrl: './flairr-stats.component.scss',
})
export class FlairrStatsComponent implements OnInit, AfterViewInit {
  isLoading = false;
  public projectViewData: qcData = {};
  public selectedProjectID: string = '';
  statsTable: any = null;

  dtOptions: any = {
    lengthMenu: [
      [10, 100, 250, 500, -1],
      [10, 100, 250, 500, 'All'],
    ],
    data: [{ id: 1 }, { id: 2 }],
    columns: [
      {
        title: 'ID',
        data: 'id',
      },
    ],
    layout: {
      topStart: {
        searchBuilder: {},
        buttons: ['copy', 'excel', 'pdf'],
      },
    },
    destroy: true,
  };

  constructor(private backendService: BackendService, public projectService: ProjectService) {
    effect(() => {
      this.setProject();
    }, {});
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  // Haplotype / novel allele charts

  // Project / locus selection

  setProject() {
    if (this.projectService.selectedProjectDetails() !== null) {
      const projectDetails = this.projectService.selectedProjectDetails();
      this.selectedProjectID = projectDetails ? projectDetails.project_id : '';

      console.log('setting project: ', this.selectedProjectID);

      this.isLoading = true;
      console.log('getting project view data');
      this.backendService.getProjectQCData(this.selectedProjectID, 'FLAIRR-Seq').subscribe((response: any) => {
        if (response.status === 200) {
          this.isLoading = false;
          this.projectViewData = response.reply;
          if (Object.keys(this.projectViewData).length > 0) {
            this.selectAnalysis(Object.keys(this.projectViewData)[0]);
          }
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  onSelectedAnalysis(event: any) {
    console.log(event);
    this.selectAnalysis(event.target.value);
  }

  selectAnalysis(key: string) {
    if (this.statsTable != null) {
      this.statsTable.destroy('true');
      $('#stats_table_holder').append('<table id="stats_table"></table>');
    }

    this.dtOptions.columns = this.projectViewData[key].cols;
    this.dtOptions.data = this.projectViewData[key].data;
    this.statsTable = $('#stats_table').DataTable(this.dtOptions);
  }
}
