import { Component, OnInit, AfterViewInit, effect, Input } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { ChartConfiguration } from 'chart.js';
import { ProjectDetails } from '@shared/api_interfaces';
import { ProjectService } from '@app/shell/header/header.project.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
  public _analysis_type: string = '';
  @Input() set analysis_type(value: string) {
    console.log('setting analysis_type: ' + value);
    this._analysis_type = value;
    this.setproject();
  }

  isLoading = false;
  public projectViewData: any = null;
  selectedLocus: string = '';
  public barChartPlugins = [];

  // Haplotype / novel allele charts
  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
  };

  public barChartNovelSupportOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: { text: 'No. of supporting haplotypes', display: true },
      },
      y: {
        display: true,
        title: { text: 'No. of novel alleles', display: true },
        type: 'logarithmic',
      },
    },
  };

  constructor(private backendService: BackendService, public projectService: ProjectService) {
    effect(() => {
      this.setproject();
    }, {});
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
  }

  // Haplotype / novel allele charts

  // Project / locus selection

  setproject() {
    this.isLoading = true;
    console.log('getting project view data');

    const projectDetails = this.projectService.selectedProjectDetails();
    if (projectDetails) {
      this.backendService
        .getProjectViewData(projectDetails.project_id, this._analysis_type)
        .subscribe((response: any) => {
          if (response.status === 200) {
            console.log('got project view data');
            this.isLoading = false;
            this.projectViewData = response;
            console.log('back from select locus');
          } else {
            this.isLoading = false;
          }
        });
    }
  }
}
