<div class="container" style="padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div *ngIf="projectFiles.length" class="row p-3">
    <h3>Project Files</h3>
    <div class="row">
      <ul class="list-inline">
        <div *ngFor="let file of projectFiles">
          <li class="list-inline-item">
            <a href="{{ file.file_red }}"> <fa-icon [icon]="file.file_icon"></fa-icon></a>
            <button
              (click)="copyLinkToClipboard(file.file_url)"
              class="btn btn-link"
              style="--bs-btn-padding-y: 0rem; --bs-btn-padding-x: 0rem"
            >
              <fa-icon [icon]="faCopy" [border]="false"></fa-icon>
            </button>
            {{ file.file_name }}
          </li>
        </div>
      </ul>
    </div>
  </div>
  <div class="row p-3">
    <h3>Project Samples</h3>
    <div id="project_files_table_holder">
      <table id="project_files_table"></table>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Link copied</h4>
  </div>
  <div class="modal-body">The download link has been copied to to your clipboard. It is valid for 24 hours.</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modalRef.hide()">OK</button>
  </div>
</ng-template>
