import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { FlairrSummaryRoutingModule } from './flairr-summary-routing.module';
import { FlairrSummaryComponent } from './flairr-summary.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    NgChartsModule,
    FontAwesomeModule,
    CommonModule,
    FlairrSummaryRoutingModule,
    DataTablesModule,
    BrowserModule,
    SharedModule,
    BsDropdownModule,
  ],
  declarations: [FlairrSummaryComponent],
})
export class FlairrSummaryModule {}
