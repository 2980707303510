import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { SharedModule } from '@shared';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';

import { Optional, SkipSelf } from '@angular/core';
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, AmplifyAuthenticatorModule, AuthRoutingModule],
  declarations: [LoginComponent],
})
export class AuthModule {
  /*  constructor(@Optional() @SkipSelf() parentModule?: AuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only');
    }
  
  }  
 */
}
