import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

import { AnalyticsComponent } from './analytics.component';

const routes: Routes = [
  Shell.publicRoutes([
    { path: 'analytics/:analysis_type', component: AnalyticsComponent, data: { title: 'Analytics' } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AnalyticsRoutingModule {}
