<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
  crossorigin="anonymous"
></script>

<div class="container-fluid" style="width: 100%; padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <h2>Project Summary Statistics</h2>

  <div *ngIf="projectViewData">
    <div class="row">
      <div id="summary_table_holder">
        <table id="summary_table"></table>
      </div>
    </div>

    <h2>Sample Diversity</h2>
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        [disabled]="!searchActive"
        (change)="onCompareSelectedRows($event)"
      />
      <label class="form-check-label" for="flexSwitchCheckDefault">Compare selected and unselected rows</label>
    </div>

    <div class="row justify-content-md-center">
      <div *ngIf="shannonPlotData" class="col-4">
        <h4>Shannon Entropy</h4>
        <canvas baseChart [data]="shannonPlotData" [options]="chartOptions" [legend]="true" [type]="'violin'"> </canvas>
      </div>
      <div *ngIf="simpsonPlotData" class="col-4">
        <h4>Simpson Index</h4>
        <canvas baseChart [data]="simpsonPlotData" [options]="chartOptions" [legend]="true" [type]="'violin'"> </canvas>
      </div>
    </div>
  </div>

  <portal #myPortal *ngIf="showPortal">
    <nav class="navbar navbar-expand-lg navbar-light" style="background: rgb(75, 152, 228)">
      <div class="container-fluid">
        <a class="navbar-brand text-white" href="https://www.clareobio.com">clareo</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>

    <div class="container-fluid" style="width: 100%; padding-left: 15px; padding-right: 15px; padding-top: 25px">
      <h2>Hiya!</h2>
    </div>
  </portal>
</div>
