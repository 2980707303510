import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retry, finalize } from 'rxjs/operators';
import { timer } from 'rxjs';

const DEFAULT_DELAY = 500;
const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_BACKOFF = 1000;

// https://medium.com/javascript-everyday/rxjs-hint-retry-e8293a204d91

function createNotifiers$(err: Error, retryCount: number, delayMs: number, backoffMs: number) {
  //console.log(`Attempt ${retryCount} failed error ${err}, retrying in ${backoffMs}ms`);
  const isPermanentError =
    err.message.includes('400') ||
    err.message.includes('401') ||
    err.message.includes('403') ||
    err.message.includes('404');
  return isPermanentError ? throwError(() => err) : timer(delayMs + retryCount * backoffMs);
}

export function retryWithBackoff(delayMs = DEFAULT_DELAY, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retry({ count: maxRetry, delay: (err, retryCount) => createNotifiers$(err, retryCount, delayMs, backoffMs) })
    );
}
