import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { ThemePickerComponent } from './theme-picker.component';
import { PortalWindowComponent } from './portal-window.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, BsDropdownModule, FontAwesomeModule],
  declarations: [LoaderComponent, ThemePickerComponent, PortalWindowComponent],
  exports: [LoaderComponent, ThemePickerComponent, PortalWindowComponent],
})
export class SharedModule {}
