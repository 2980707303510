import { Title } from '@angular/platform-browser';
import { Component, OnInit, AfterViewInit, signal, effect } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CredentialsService } from '@app/auth';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { BackendService } from '@shared/backend.service';
import { ProjectDetails } from '@shared/api_interfaces';
import { ProjectService } from './header.project.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  faUser = faUser;
  user: any;
  access_token: any;
  public selectedProjectDetails = signal<ProjectDetails | null>(null);

  constructor(
    public projectService: ProjectService,
    private router: Router,
    private titleService: Title,
    public credentialsService: CredentialsService
  ) {
    effect(
      () => {
        this.projectService.fetchProjectDetails();
      },
      { allowSignalWrites: true }
    );
  }

  login() {}

  logout() {
    Auth.signOut();
    this.router.navigate(['/home'], { replaceUrl: true });
  }
}
