import { NgModule } from '@angular/core';
import { ProjectFilesComponent } from './project-files.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

import { SharedModule } from '@shared';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectFilesRoutingModule } from './project-files-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    FontAwesomeModule,
    DataTablesModule,
    ProjectFilesRoutingModule,
  ],
  declarations: [ProjectFilesComponent],
  bootstrap: [ProjectFilesComponent],
})
export class ProjectFilesModule {}
