import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

import { GeneAnalyticsComponent } from './gene-analytics.component';

const routes: Routes = [
  Shell.publicRoutes([
    { path: 'gene-analytics/:analysis_type', component: GeneAnalyticsComponent, data: { title: 'GeneAnalytics' } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class GeneAnalyticsRoutingModule {}
