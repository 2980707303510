// open a portal in another window
// based on https://stackoverflow.com/questions/74577553/in-angular-with-a-portal-opened-in-another-window-how-do-i-attach-an-overlayre
// also https://stackblitz.com/edit/angular-open-window-portal?file=src%2Fapp%2Fapp.component.ts

// for dynamic content, see https://angular-dynamic-hooks.com/

import {
  Component,
  ViewChild,
  ApplicationRef,
  Injector,
  OnDestroy,
  AfterViewInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'portal',
  template: `
    <ng-template #cdkPortal>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class PortalWindowComponent implements AfterViewInit, OnDestroy {
  // STEP 1: get a reference to the portal
  @ViewChild('cdkPortal') cdkPortal!: TemplateRef<unknown>;

  // STEP 2: save a reference to the window so we can close it
  private externalWindow: Window | null = null;
  public externalPortalOutlet!: DomPortalOutlet;

  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(
    private applicationRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector
  ) {}

  ngAfterViewInit() {
    // STEP 4: create an external window
    this.externalWindow = window.open('', 'foo');
    if (!this.externalWindow) {
      return;
    }

    const portal = new TemplatePortal(this.cdkPortal, this.viewContainerRef);

    this.externalPortalOutlet = new DomPortalOutlet(
      this.externalWindow.document.body,
      undefined,
      this.applicationRef,
      this.injector
    );

    // STEP 5: Attach the portal
    this.externalPortalOutlet.attach(portal);
  }

  ngOnDestroy() {
    // STEP 7: close the window when this component destroyed

    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  public appendStyles() {
    if (this.externalWindow) {
      document.querySelectorAll('link, style').forEach((htmlElement) => {
        this.externalWindow!.document.head.appendChild(htmlElement.cloneNode(true));
      });

      // set current theme from local storage

      const theme = localStorage.getItem('theme');
      if (theme) {
        this.externalWindow!.document.documentElement.setAttribute('data-bs-theme', theme);
        if (theme === 'dark') {
          this.externalWindow!.document.documentElement.classList.add('dark');
        } else {
          this.externalWindow!.document.documentElement.classList.remove('dark');
        }
      }
    }
  }
}
