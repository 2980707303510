import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from '@shared';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { AnalyticsComponent } from './analytics.component';

@NgModule({
  imports: [CommonModule, SharedModule, NgChartsModule, AnalyticsRoutingModule, BrowserModule],
  declarations: [AnalyticsComponent],
})
export class AnalyticsModule {}
