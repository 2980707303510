import { ElementRef, Injectable, signal } from '@angular/core';
import { ProjectDetails } from '@shared/api_interfaces';
import { BackendService } from '@shared/backend.service';
import { CredentialsService } from '@app/auth';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  // private writeable signals
  private _projectDetails = signal<ProjectDetails[]>([]);
  private _selectedProjectDetails = signal<ProjectDetails | null>(null);

  // public read-only signals
  public readonly projectDetails = this._projectDetails.asReadonly();
  public readonly selectedProjectDetails = this._selectedProjectDetails.asReadonly();

  constructor(private backendService: BackendService, private credentialsService: CredentialsService) {}

  // update methods
  fetchProjectDetails() {
    if (this.credentialsService.credentials()) {
      this.backendService.getUserProjectDetails().subscribe((response: any) => {
        if (response.project_data) {
          const pd = response.project_data;
          this._projectDetails.set(pd);

          if (pd && pd.length > 0) {
            this._selectedProjectDetails.set(pd[0]);
          }
        } else {
          console.log('project data was not retrieved from the backend');
          this._projectDetails.set([]);
          this._selectedProjectDetails.set(null);
        }
      });
    } else {
      this._projectDetails.set([]);
      this._selectedProjectDetails.set(null);
    }
  }

  setCurrentProjectFromEvent(projectId: Event) {
    const projectIdStr = (projectId.target as HTMLSelectElement).value;
    this.setCurrentProject(projectIdStr);
  }

  setCurrentProject(projectId: string) {
    const pd = this._projectDetails();
    const selected = pd.find((p) => p.project_id === projectId);
    if (selected) {
      this._selectedProjectDetails.set(selected);
    } else {
      console.log(`setCurrentProject: project ${projectId} not found`);
    }
  }
}
